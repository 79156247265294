/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isSameDay } from 'date-fns';
import TextInput from './TextInput';
import { isValidDate } from '../../utils/dateUtils';
import Labeled from '../misc/Labeled';

interface BirthDateInputProps {
  date: Date | null | undefined;
  setDate: (date: Date | null | undefined) => void;
  errorMessage?: string;
  valid?: boolean;
  onBlur?: () => void;
}

export default function BirthDateInput({
  date,
  setDate,
  errorMessage,
  valid,
  onBlur = (): void => {},
}: BirthDateInputProps): JSX.Element {
  const { t } = useTranslation();
  const [day, setDay] = useState<string>(date?.getDate().toString() ?? '');
  const [month, setMonth] = useState<string>(
    date?.getMonth() ? (date.getMonth() + 1).toString() : '',
  );
  const [year, setYear] = useState<string>(date?.getFullYear().toString() ?? '');

  const newDate = new Date(+year, +month - 1, +day);

  useEffect((): void => {
    if (!day && !month && !year) {
      setDate(undefined);
      return;
    }
    if (!day || !month || !year || !isValidDate(+day, +month, +year)) {
      setDate(null);
      return;
    }
    setDate(newDate);
  }, [day, month, year]);

  useEffect((): void => {
    if (date && date.toString() !== 'Invalid Date' && !isSameDay(date, newDate)) {
      const parsedDate = new Date(date);
      setDay(parsedDate.getDate().toString());
      setMonth((parsedDate.getMonth() + 1).toString());
      setYear(parsedDate.getFullYear().toString());
    }
  }, [date]);

  return (
    <Labeled
      label={`${t('page.userDetails.label.birthdate')}:`}
      className="flex flex-col gap-1 w-full">
      <div className="flex gap-2 w-full">
        <TextInput
          placeholder="DD"
          text={day}
          setText={setDay}
          type="number"
          valid={valid}
          onBlur={(): void => {
            if (day.length === 1) setDay(`0${day}`);
            onBlur();
          }}
        />
        <TextInput
          placeholder="MM"
          text={month}
          setText={setMonth}
          valid={valid}
          onBlur={(): void => {
            if (month.length === 1) setMonth(`0${month}`);
            onBlur();
          }}
        />
        <TextInput placeholder="YYYY" valid={valid} text={year} setText={setYear} onBlur={onBlur} />
      </div>
      <label className="text-sb-pink text-[15px] leading-[21px] ml-2.5">{errorMessage}</label>
    </Labeled>
  );
}
