import { isBefore, isSameDay } from 'date-fns';
import { DateTime } from 'luxon';

const MIN_BIRTH_YEAR = 1900;

export function isValidDate(day: number, month: number, year: number): boolean {
  const date = new Date(year, month - 1, day);

  const isValid =
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day &&
    year >= MIN_BIRTH_YEAR;

  return isValid;
}

export function makeTimeIrrelevantDate(date: Date, timeZone = 'utc'): Date {
  return DateTime.fromJSDate(date)
    .setZone(timeZone)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .toJSDate();
}

function getMonday(date: DateTime): DateTime {
  const day = date.weekday;
  const diff = day === 1 ? 0 : 1 - day; // Monday is 1
  return date.plus({ days: diff }).startOf('day');
}

function getSunday(date: DateTime): DateTime {
  const day = date.weekday;
  const diff = day === 7 ? 0 : 7 - day; // Sunday is 7
  return date.plus({ days: diff }).startOf('day');
}

export function getAllDaysInMonthAtMidnight(monthsFromNow: number, timeZone: string): Date[] {
  const now = DateTime.now().setZone(timeZone);

  const targetDate = now.plus({ months: monthsFromNow });
  const { year } = targetDate;
  const { month } = targetDate;

  const start = DateTime.fromObject({ year, month, day: 1 }, { zone: timeZone }).startOf('day');
  const end = start.endOf('month');

  const firstMonday = getMonday(start.startOf('month').minus({ days: start.day - 1 }));
  const lastSunday = getSunday(end);

  const days = [];
  let current = firstMonday;

  while (current <= lastSunday) {
    days.push(current.toJSDate());
    current = current.plus({ days: 1 });
  }

  return days;
}

export function isBeforeDay(a: Date, b: Date): boolean {
  return isBefore(a, b) && !isSameDay(a, b);
}

export function absoluteMonthDifference(a: Date, b: Date): number {
  return a.getMonth() - b.getMonth() + 12 * (a.getFullYear() - b.getFullYear());
}

export function absoluteDayDifference(a: Date, b: Date): number {
  return Math.abs(Math.floor((a.getTime() - b.getTime()) / (1000 * 60 * 60 * 24)));
}

export function dateMax(a: Date, b: Date): Date {
  return a > b ? a : b;
}
