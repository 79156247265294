// eslint-disable-next-line import/no-cycle
import { cartService, userDetailsService } from '.';
import { createBackup } from '../redux/slices/shoppingCartSlice';
import { store } from '../redux/store';
import {
  CreateTicketPaymentDto,
  CreateTicketPaymentResponceDto,
  PaymentMethod,
  PaymentMethodId,
} from '../types/payment';
import httpService from './httpService';

export async function getPaymentMethods(): Promise<PaymentMethod[]> {
  return httpService
    .get<PaymentMethod[]>('/api/paymentmethods')
    .then(({ data }): PaymentMethod[] => data);
}

async function getCreatePaymentDto(method: PaymentMethodId, agreement: string[]): Promise<CreateTicketPaymentDto> {
  const userDetails = await userDetailsService
    .getUserDetailsTypeFromUserDetailsInputType(store.getState().userDetails.userDetails ?? {})
    .catch((err): Promise<never> => Promise.reject(Error(err.message)));
  if (!store.getState().shoppingCart.items || !store.getState().shoppingCart.items.length) {
    return Promise.reject(Error('SHOPPING_CART_EMPTY'));
  }
  return Promise.resolve({
    method: method.method,
    issuer: method.issuer || '',
    userDetails: {
      ...userDetails,
      agreement,
      languagePreference: store.getState().application.language,
    },
    purchases: store.getState().shoppingCart.purchases,
    bookingFee: store.getState().shoppingCart.price.bookingFee.price,
    discountedBookingFee: store.getState().shoppingCart.price.bookingFee.discountedPrice,
    usedCodes: store.getState().shoppingCart.discounts.filter((d): boolean => d.times > 0),
  });
}

export async function createPayment(method: PaymentMethodId, agreement: string[]): Promise<any> {
  store.dispatch(createBackup());
  await cartService.updateCart().catch((err): Promise<never> => Promise.reject(Error(err.message)));
  return getCreatePaymentDto(method, agreement).then((dto): Promise<string | void> =>
    httpService
      .post<CreateTicketPaymentResponceDto>('/api/locations/:locationId/ticket-shops/:ticketShopId/payments/', dto)
      .then(({ data }): string => data.url)
      .catch((error): Promise<never> => Promise.reject(Error(error.message))),
  ).catch((err): Promise<never> => Promise.reject(Error(err.message)));
}

export async function checkPaymentStatus(paymentToken: string): Promise<string> {
  return httpService
    .post<string>(`/api/payments/status/:locationId`, {
      paymentToken,
    })
    .then(({ data }): string => data);
}

export async function getReservations(payment: string): Promise<Buffer> {
  return httpService
    .post<Buffer>(
      '/api/reservations/',
      { paymentToken: payment },
      { headers: { 'Content-Type': 'application/json' }, responseType: 'arraybuffer' },
    )
    .then(({ data }): Buffer => data);
}