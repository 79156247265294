import * as EmailValidator from 'email-validator';
import { UserDetailsInputType } from '../types/userDetails';
import i18n from '../locales/i18n';

export const userDetailsValidators = {
  salutation: (value: string): string => {
    if (!value.length)
      return `${i18n.t('page.userDetails.label.salutation')} ${i18n.t(
        'page.userDetails.error.required',
      )}`;
    return '';
  },
  firstName: (value: string): string => {
    if (!value.length)
      return `${i18n.t('page.userDetails.label.firstName')} ${i18n.t(
        'page.userDetails.error.required',
      )}`;
    return '';
  },
  lastName: (value: string): string => {
    if (!value.length)
      return `${i18n.t('page.userDetails.label.lastName')} ${i18n.t(
        'page.userDetails.error.required',
      )}`;
    return '';
  },
  email: (value: string): string => {
    if (!value.length)
      return `${i18n.t('page.userDetails.label.email')} ${i18n.t(
        'page.userDetails.error.required',
      )}`;
    if (!EmailValidator.validate(value)) return i18n.t('page.userDetails.error.invalidEmail');
    return '';
  },
  confirmEmail: (value: string, userDetailsEmail: string): string => {
    if (!value.length) return i18n.t('page.userDetails.error.emailConfirmationRequired');
    if (value !== userDetailsEmail) return i18n.t('page.userDetails.error.emailMismatch');
    if (!EmailValidator.validate(value)) return i18n.t('page.userDetails.error.invalidEmail');
    return '';
  },
  zipCode: (value: string): string => {
    if (!value.length)
      return `${i18n.t('page.userDetails.label.zipCode')} ${i18n.t(
        'page.userDetails.error.required',
      )}`;
    return '';
  },
  city: (value: string): string => {
    if (!value.length)
      return `${i18n.t('page.userDetails.label.city')} ${i18n.t(
        'page.userDetails.error.required',
      )}`;
    return '';
  },
  birthdate: (value: Date | null | undefined): string => {
    if (value === undefined) return '';
    if (value === null) return i18n.t('page.userDetails.error.invalidBirthdate');
    return '';
  },
};

export function validateUserDetails(
  userDetails: UserDetailsInputType,
): Record<keyof UserDetailsInputType, string> {
  const { confirmEmail, email, birthdate } = userDetails;
  return Object.keys(userDetailsValidators).reduce((acc, key): Record<keyof UserDetailsInputType, string> => {
    if (key === 'confirmEmail')
      acc.confirmEmail = userDetailsValidators[key](confirmEmail ?? '', email ?? '');
    else if (key === 'birthdate') acc.birthdate = userDetailsValidators[key](birthdate);
    else
      acc[key as keyof UserDetailsInputType] = userDetailsValidators[
        key as keyof Omit<typeof userDetailsValidators, 'confirmEmail' | 'birthdate'>
      ](userDetails?.[key as keyof Omit<UserDetailsInputType, 'birthdate'>] ?? '');
    return acc;
  }, {} as Record<keyof UserDetailsInputType, string>);
}

export const rotterdampasValidators = {
  cardId: (value: string): string => {
    if (!value.length)
      return `${i18n.t('component.rotterdampas.label.cardId')} ${i18n.t(
        'component.rotterdampas.error.required',
      )}`;
    return '';
  },
  pincode: (value: string): string => {
    if (!value.length)
      return `${i18n.t('component.rotterdampas.label.pincode')} ${i18n.t(
        'component.rotterdampas.error.required',
      )}`;
    if (value.length !== 6)
      return `${i18n.t('component.rotterdampas.label.pincode')} ${i18n.t(
        'component.rotterdampas.error.minmax6',
      )}`;
    return '';
  }
}

