import { useEffect, useState } from 'react';

export default function useMediaQuery(): (minWidth: number) => boolean {
  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = (): void => setWidth(window.innerWidth);

  useEffect((): (() => void) => {
    window.addEventListener('resize', handleResize);
    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (minWidth: number): boolean => width >= minWidth;
}
