import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ShopState } from '../../types/redux';
import { TicketTypesByCategoryId } from '../../types/order';
import { makeTimeIrrelevantDate } from '../../utils/dateUtils';

const initialState: ShopState = {
  lowestPrices: {},
  ticketTypes: {},
  dateOfOrder: makeTimeIrrelevantDate(new Date()),
  pageIndex: 0,
  timeZone: 'Europe/Amsterdam',
  locationName: '',
};

const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    /**
     * Sets the lowest prices for the shop
     * @param state Current state of the shop
     * @param action Action made to the shop. Contains the lowest prices to set
     */
    setLowestPrices: (state, action: PayloadAction<Record<string, number>>): void => {
      // updates existing lowest prices and preserves the rest
      state.lowestPrices = { ...state.lowestPrices, ...action.payload };
    },

    /**
     * Sets the date of order for the shop
     * @param state Current state of the shop
     * @param action Action made to the shop. Contains the date to set
     */
    setDateOfOrder: (state, action: PayloadAction<Date>): void => {
      state.dateOfOrder = action.payload;
    },

    /**
     * Sets the ticket types for the shop
     * @param state Current state of the shop
     * @param action Action made to the shop. Contains the ticket types to set
     */
    setTicketTypes: (state, action: PayloadAction<TicketTypesByCategoryId>): void => {
      state.ticketTypes = action.payload;
    },

    /**
     * Sets the page index for the shop
     * @param state Current state of the shop
     * @param action Action made to the shop. Contains the page index to set
     */
    setPageIndex: (state, action: PayloadAction<number>): void => {
      state.pageIndex = action.payload;
    },

    setShop(state, action: PayloadAction<{ name: string; timeZone: string; locationName: string }>): void {
      state.shopName = action.payload.name;
      state.timeZone = action.payload.timeZone;
      state.locationName = action.payload.locationName;
    },
  },
});

const shopReducer = shopSlice.reducer;

export const { setLowestPrices, setDateOfOrder, setTicketTypes, setPageIndex, setShop } =
  shopSlice.actions;
export default shopReducer;
