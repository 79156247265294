import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useQuery from './useQuery';
import { DefaultURLQuery } from '../types/misc';
import { DATE_PICKING_ROUTE, ORDER_ROUTE, USER_DETAILS_ROUTE } from '../constants';
import { cartService, ticketShopService } from '../services';
import { makeTimeIrrelevantDate } from '../utils/dateUtils';
import useShop from './selectors/useShop';

export default function useCrashHandler(): (err: Error) => void {
  const navigate = useNavigate();
  const { timeZone } = useShop();
  const query = useQuery<DefaultURLQuery>();
  const { t } = useTranslation('translation', { keyPrefix: 'error' });
  return (err: Error): void => {
    switch (err.message) {
      case 'Network Error':
        toast.error(t('network_error'));
        break;
      case 'REPAIR_FAILED':
        toast.error(t('handle_crash'));
        navigate(`${DATE_PICKING_ROUTE}?lId=${query.lId}&tId=${query.tId}`);
        break;
      case 'USER_DETAILS_MISSING':
        toast.error(t('user_details_missing'));
        navigate(`${USER_DETAILS_ROUTE}?lId=${query.lId}&tId=${query.tId}`);
        break;
      case 'SHOPPING_CART_EMPTY':
        toast.error(t('shopping_cart_empty'));
        navigate(`${ORDER_ROUTE}?lId=${query.lId}&tId=${query.tId}`);
        break;
      case 'PRICES_CHANGED':
        toast.warning(t('prices_changed'));
        break;
      case 'SOMETHING_BIG_CHANGED':
        toast.error(t('big_change'));
        cartService.repairShop();
        navigate(`${ORDER_ROUTE}?lId=${query.lId}&tId=${query.tId}`);
        break;
      case 'INVALID_DATE':
        toast.error(t('invalid_date'));
        navigate(`${DATE_PICKING_ROUTE}?lId=${query.lId}&tId=${query.tId}`);
        ticketShopService.setDateOfOrderToShop(makeTimeIrrelevantDate(new Date(), timeZone));
        break;
      case 'MISSING_QUERY_PARAMS':
        navigate('/not-found');
        break;
      default:
        toast.error(t('went_wrong'));
        console.error(err);
    }
  };
}
