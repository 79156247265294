import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ContentPanel from '../components/layouts/ContentPanel';
import ProgressComponent from '../components/misc/ProgressComponent';
import SuccessFailureCircle from '../components/misc/SuccessFailureCircle';
import Button from '../components/buttons/Button';
import ExtendedLink from '../routes/ExtendedLink';
import { NOT_FOUND_ROUTE, PAYMENT_ROUTE } from '../constants';
import useQueryParams from '../hooks/useQuery';
import { DefaultURLQuery, ProgressStatus } from '../types/misc';

export default function OrderFailurePage(): JSX.Element {
  const { payment, lId, tId } = useQueryParams<DefaultURLQuery>();
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'page.orderFailure' });

  useEffect((): void => {
    if (!payment || !lId || !tId) {
      navigate(NOT_FOUND_ROUTE);
    }
  }, []);

  return (
    <>
      <div className="flex w-full lg:w-1/3 lg:pt-5 mx-auto ">
      <ProgressComponent status={ProgressStatus.FAILURE} />
      </div>
      <ContentPanel className="items-center gap-8 pt-16 lg:pb-16">
        <SuccessFailureCircle status={ProgressStatus.FAILURE} />
        <p className="text-[17px] leading-[22px] text-center lg:max-w-[350px]">{t('description')}</p>
        <ExtendedLink to={PAYMENT_ROUTE}>
          <Button variant="pink">{t('button')}</Button>
        </ExtendedLink>
      </ContentPanel>
    </>
  );
}
