import { AnyAction, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import rootReducer from './reducer';
import { shopTransform, userDetailsTransform } from './transformers';

const persistConfig = {
  key: 'root',
  storage,
  transforms: [shopTransform, userDetailsTransform],
};

const persistedReducer = persistReducer<ReturnType<typeof rootReducer>, AnyAction>(
  persistConfig,
  rootReducer,
);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export const persistor = persistStore(store);
