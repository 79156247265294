import { EffectCallback, MutableRefObject, useEffect } from 'react';

export default function useOutsideClickEffect(
  callback: EffectCallback,
  ref: MutableRefObject<any>,
): void {
  useEffect((): (() => void) => {
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
