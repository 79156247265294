import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ShoppingCartState } from '../../types/redux';
import { UsedDiscountCode } from '../../types/discount';
import { DiscountedTicketOrderForCartDto, TicketPurchaseDto } from '../../types/cart';

const initialState: ShoppingCartState = {purchases: [], items: [], discounts: [], price: {totalPrice: {price: 0}, bookingFee: {price: 0}}, countOfItems: 0};

const shoppingCartSlice = createSlice({
  name: 'shoppingCart',
  initialState,
  reducers: {

    /**
     * Adds a discount to the shopping cart
     * @param state Current state of the shopping cart
     * @param action Action made to the shopping cart. Contains the discount to add
     */
    addDiscount(state, action: PayloadAction<UsedDiscountCode>): void {
      if (!state.discounts.find((discount): boolean => discount.code === action.payload.code)) {
        state.discounts.push(action.payload);
      }
    },

    /**
     * Removes a discount from the shopping cart
     * @param state Current state of the shopping cart
     * @param action Action made to the shopping cart. Contains the code of the discount to remove
     */
    removeDiscount(state, action: PayloadAction<string>): void {
      state.discounts = state.discounts.filter((discount): boolean => discount.code !== action.payload);
    },

    /**
     * Sets the quantity of an item in the shopping cart
     * @param state Current state of the shopping cart
     * @param action Action made to the shopping cart. Contains the TicketPurchaseDto to set
     */
    setItemWithQuantity(state, action: PayloadAction<TicketPurchaseDto>): void {
      const item = state.purchases.find(
        (cur): boolean =>
          cur.ticketTypeId === action.payload.ticketTypeId &&
          cur.slotId === action.payload.slotId &&
          cur.discountedPrice === action.payload.discountedPrice,
      );
      if (item) {
        item.count = action.payload.count;
        item.rotterdampasses = action.payload.rotterdampasses;
      } else {
        state.purchases.push(action.payload);
      }
    },

    /**
     * Deletes an item from the shopping cart
     * @param state Current state of the shopping cart
     * @param action Action made to the shopping cart. Contains the TicketPurchaseDto to delete
     */
    deleteItem(state, action: PayloadAction<TicketPurchaseDto>): void {
      const item = state.purchases.find(
        (cur): boolean =>
          cur.ticketTypeId === action.payload.ticketTypeId &&
          cur.slotId === action.payload.slotId &&
          cur.discountedPrice === action.payload.discountedPrice,
      );
      if (item) {
        if (item.count > 1) {
          item.count -= 1;
        } else {
          state.purchases = state.purchases.filter(
            (cur): boolean =>
              cur.ticketTypeId !== action.payload.ticketTypeId ||
              cur.slotId !== action.payload.slotId ||
              cur.discountedPrice !== action.payload.discountedPrice,
          );
        }
      }
    },

    /**
     * Deletes all items of a kind from the shopping cart
     * @param state Current state of the shopping cart
     * @param action Action made to the shopping cart. Contains the TicketPurchaseDto to delete
     */
    deleteAllItemsOfAKind(state, action: PayloadAction<TicketPurchaseDto>): void {
      state.purchases = state.purchases.filter((cur): boolean => cur.ticketTypeId !== action.payload.ticketTypeId || cur.slotId !== action.payload.slotId || cur.discountedPrice !== action.payload.discountedPrice);
    },

    /**
     * Clears the shopping cart. Only dicount codes are kept
     * @param state Current state of the shopping cart
     */
    clearCart(state): void {
      state.purchases = initialState.purchases;
      state.items = initialState.items;
      state.price = initialState.price;
      state.countOfItems = initialState.countOfItems;
    },

    setPurchases(state, action: PayloadAction<TicketPurchaseDto[]>): void {
      state.purchases = action.payload;
    },

    /**
     * Sets the booking fee of the shopping cart
     * @param state Current state of the shopping cart
     * @param action Action made to the shopping cart. Contains the new booking fee
     */
    setBookingFee(state, action: PayloadAction<number>): void {
      state.price.bookingFee.price = action.payload;
    },

    /**
     * Sets the shopping cart to the given cart
     * @param state Current state of the shopping cart
     * @param action Action made to the shopping cart. Contains the new cart as a DiscountedTicketOrderForCartDto
     */
    setCart(state, action: PayloadAction<DiscountedTicketOrderForCartDto>): void {
      state.discounts = [
        ...action.payload.usedCodes,
        ...state.discounts.filter((discount): boolean => !action.payload.usedCodes
          .map((code: UsedDiscountCode): string => code.code)
          .includes(discount.code))
          .map((discount): { code: string, times: number } => ({ code: discount.code, times: 0 })),
      ];
      state.items = action.payload.items;
      state.purchases = action.payload.purchases;
      state.price.totalPrice.price = action.payload.total;
      state.price.totalPrice.discountedPrice = action.payload.discountedTotal !== action.payload.total ? action.payload.discountedTotal : undefined;
      state.price.bookingFee.price = action.payload.bookingFee;
      state.price.bookingFee.discountedPrice = action.payload.discountedBookingFee !== action.payload.bookingFee ? action.payload.discountedBookingFee : undefined;
      state.countOfItems = action.payload.purchases.reduce((acc, cur): number => acc + cur.count, 0);
    },
    createBackup(state): void {
      state.backup = { ...state, backup: undefined };
    },
    restoreCart(state): void {
      if (state.backup) {
        state.purchases = state.backup.purchases;
        state.items = state.backup.items;
        state.discounts = state.backup.discounts;
        state.price = state.backup.price;
        state.countOfItems = state.backup.countOfItems;
        state.backup = undefined;
    }
  },
  deleteBackup(state): void {
    state.backup = undefined;
  },

}});

const shoppingCartReducer = shoppingCartSlice.reducer;

export const { addDiscount, removeDiscount, setItemWithQuantity, deleteItem, deleteAllItemsOfAKind, clearCart, setBookingFee, setPurchases, setCart, createBackup, restoreCart, deleteBackup} = shoppingCartSlice.actions;

export default shoppingCartReducer;
