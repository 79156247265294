import { IntersolveResponse, RotterdampasDto } from "../types/rotterdampas";
import httpService from "./httpService";

/**
 * Validate a Rotterdampas
 * 
 * @returns intersolve response
 */
export function validateRotterdampas(rotterdampasDto: RotterdampasDto): Promise<IntersolveResponse> {
    return httpService
      .post(`/api/rotterdampas/validate`, rotterdampasDto)
      .then((response): IntersolveResponse => response.data);
}