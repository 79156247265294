import classNames from 'classnames';
import React, { InputHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type TextInputProps = {
  text?: string | number;
  setText?: (text: string) => void;
  valid?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export default function TextInput({
  text,
  setText,
  className,
  value,
  onChange,
  valid,
  ...props
}: TextInputProps): JSX.Element {
  return (
    <input
      {...props}
      type={props.type ? props.type : "text"}
      className={twMerge(
        'rounded-sb-md bg-sb-light-blue border-0 placeholder:text-opacity-35 placeholder:text-black h-[60px] px-6 text-[17px] leading-[21px] w-full',
        classNames({
          'bg-sb-purple': valid,
          'bg-sb-light-pink border border-sb-pink': valid === false,
        }),
        className,
      )}
      value={value ?? text ?? ''}
      onChange={onChange ?? ((e): void => setText?.(e.target.value))}
    />
  );
}
