export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3500';

export const SB_PINK = '#EF02FA';
export const SB_BLUE = '#005AFF';
export const SB_PURPLE = '#7F68E4';
export const SB_GREEN = '#9CF3CA';
export const SB_LIGHT_PINK = '#EF02FA4D';
export const SB_LIGHT_BLUE = '#8FD3EA66';
export const SB_LIGHT_PURPLE = '#7F68E44D';
export const SB_LIGHT_GREEN = '#9CF3CA4D';
export const SB_DARK_PURPLE = '#191A36';

export const WEB_PAGE_URL = 'https://schaatsbaanrotterdam.nl/';
export const DATE_PICKING_ROUTE = '/date';
export const ORDER_ROUTE = '/order';
export const USER_DETAILS_ROUTE = '/user-details';
export const PAYMENT_ROUTE = '/payment';
export const ORDER_SUCCESS_ROUTE = '/order/success';
export const ORDER_PENDING_ROUTE = '/order/pending';
export const ORDER_FAILURE_ROUTE = '/order/failure';
export const ORDER_PROCEEDING_ROUTE = '/order/proceeding';
export const NOT_FOUND_ROUTE = '/not-found';

export const SHOP_ROUTES = [DATE_PICKING_ROUTE, ORDER_ROUTE, USER_DETAILS_ROUTE, PAYMENT_ROUTE];
