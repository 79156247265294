import { useEffect, useRef } from 'react';

function useEffectAfterFirstRender(effect: () => void, dependencies: React.DependencyList): void {
  const isFirstRender = useRef(true);

  useEffect((): void | (() => void) => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return (): void => {};
    }
    return effect();
  }, dependencies);
}

export default useEffectAfterFirstRender;
