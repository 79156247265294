// eslint-disable-next-line import/no-cycle
import { ticketShopService } from '.';
import { addDiscount, clearCart, createBackup, deleteAllItemsOfAKind, deleteBackup, removeDiscount, restoreCart, setCart, setItemWithQuantity, setPurchases } from '../redux/slices/shoppingCartSlice';
import { store } from '../redux/store';
import { DiscountableTicketOrderDto, DiscountedTicketOrderDto } from '../types/discount';
import httpService from './httpService';
import { DiscountedTicketOrderForCartDto, ShoppingCartItem, TicketPurchaseDto } from '../types/cart';
import { getTicketTypeById } from './ticketShopService';
import { makeTimeIrrelevantDate } from '../utils/dateUtils';

/**
 * Helper function to get the DiscountableTicketOrderDto from the current shopping cart state 
 * @returns {DiscountableTicketOrderDto} The DiscountableTicketOrderDto from the current shopping cart state
 */
function getDiscountableTicketOrderDto(): DiscountableTicketOrderDto {
    return {
        purchases: store.getState().shoppingCart.purchases,
        discountCodes: store.getState().shoppingCart.discounts.map((discount): string => discount.code),
    };
}

/**
 * Transforms a discounted order received from the server to a discounted order for the shopping cart
 * @param discountedOrder The discounted order received from the server
 * @returns {DiscountedTicketOrderForCartDto} The discounted order for the shopping cart
 */
function getDiscountedOrderDtoForCart(discountedOrder: DiscountedTicketOrderDto): DiscountedTicketOrderForCartDto {
  const items: ShoppingCartItem[] = discountedOrder.purchases.map((purchase: TicketPurchaseDto): ShoppingCartItem => {
    //TODO: cache data?
    const ticketSlotMetadata = ticketShopService.getTicketTypeWithSlotMetadata(purchase.ticketTypeId, purchase.slotId);
    if (!ticketSlotMetadata) {
      throw new Error('SOMETHING_BIG_CHANGED');
    }
    return {
      id: `${purchase.ticketTypeId}-${purchase.slotId}-${purchase.discountedPrice ||'undefined'}`,
      ticketTypeName: ticketSlotMetadata.name,
      slotTime: ticketSlotMetadata.slotTime,
      totalPrice: {
        price: purchase.price * purchase.count,
        discountedPrice: purchase.discountedPrice ? purchase.discountedPrice * purchase.count : undefined,
      },
      categoryId: ticketSlotMetadata.ticketCategoryId,
      ticketPurchaseDto: purchase,
    };
  });
  return {
    purchases: discountedOrder.purchases,
    items,
    bookingFee: discountedOrder.bookingFee,
    discountedBookingFee: discountedOrder.discountedBookingFee,
    total: discountedOrder.total,
    discountedTotal: discountedOrder.discountedTotal,
    usedCodes: discountedOrder.usedCodes,
  };
}

/**
 * Sets the shopping cart to the discounted order received from the server
 */
async function setDiscountedOrderDto(): Promise<void> {
    await httpService
        .post<DiscountedTicketOrderDto>('api/locations/:locationId/ticket-shops/:ticketShopId/cart', getDiscountableTicketOrderDto())
        .then(({ data }): void => {
            store.dispatch(setCart(getDiscountedOrderDtoForCart(data)));
            store.dispatch(deleteBackup());
        })
        .catch((error): Promise<never> => {
          if (error.message === 'Network Error') {
              return Promise.reject(error);
          } if (error.response.status === 400 || error.response.status === 404) {
              store.dispatch(restoreCart());
              return Promise.reject(new Error('SOMETHING_BIG_CHANGED'));
          } if (error.response.status === 409) {
              store.dispatch(setCart(getDiscountedOrderDtoForCart(error.response.data)));
              store.dispatch(deleteBackup());
              return Promise.reject(new Error('PRICES_CHANGED'));
          }
          store.dispatch(restoreCart()); 
          return Promise.reject(new Error('UNKNOWN_ERROR'));
        });
}


/**
 * Wrapper around setDiscountedOrderDto. Updates the shopping cart with the received data from the server
 * @returns {Promise<void>} A promise that resolves when the shopping cart has been updated
 */
export async function updateCart(inRepair?: boolean): Promise<void> {
    const { dateOfOrder, timeZone } = store.getState().shop;
    if (dateOfOrder.getTime() < makeTimeIrrelevantDate(new Date(), timeZone).getTime()) {
      return Promise.reject(new Error('INVALID_DATE'));
    }
    return setDiscountedOrderDto()
    .catch(async (error): Promise<never> => {
        if (error.message === 'Network Error') {
            return Promise.reject(error);
        } if(inRepair) {
            localStorage.clear(); //TODO: Keep language preference
            return Promise.reject(new Error('REPAIR_FAILED'));
        } if (error.message === 'SOMETHING_BIG_CHANGED') {
            await repairShop();
            return Promise.reject(error);
        } if (error.message === 'PRICES_CHANGED') {
          return ticketShopService
            .fetchTicketTypes()
            .then((): Promise<never> => Promise.reject(error))
            .catch((err): Promise<never> => Promise.reject(err));
        }
        await repairShop();
        return Promise.reject(error);
    });
}

/**
 * Repairs the shopping cart by refetching the items in the shop and setting the purchses accordingly
 */
export async function repairShop(): Promise<void> {
  await ticketShopService.fetchTicketTypes().catch((err): Promise<never> => Promise.reject(new Error(err.message)));
  const currentPurchases = Object.values(store.getState().shoppingCart.purchases.reduce((acc, cur): Record<string, TicketPurchaseDto> => {
    const key = `${cur.ticketTypeId}-${cur.slotId}`;
    if (acc[key]) {
      acc[key].count += cur.count;
    } else {
      acc[key] = cur;
    }
    return acc;
  }, {} as Record<string, TicketPurchaseDto>));
  const repairedPurchases = currentPurchases.map((p): TicketPurchaseDto => {
    const metadata = ticketShopService.getTicketTypeWithSlotMetadata(p.ticketTypeId, p.slotId)
    if (!metadata) {
      return {...p, count: 0};
    }
    const maxCount = Math.min(metadata.highBoundTicketCount, metadata.ticketsLeft);
    const lowBound = metadata.lowBoundTicketCount;
    return {
      ...p,
      count: Math.min(maxCount, Math.max(lowBound, p.count)),
    };
  }).filter((p): boolean => p.count > 0);
  store.dispatch(createBackup());
  store.dispatch(setPurchases(repairedPurchases));
  await updateCart(true).catch((error): void => {
    Promise.reject(error);
  });
}


/**
 * Adds a new item to the shopping cart
 * @param {TicketPurchaseDto} purchase The item to be added to the shopping cart
 * @returns {Promise<void>} A promise that resolves when the item has been added to the shopping cart
 */
export function addNewItemToShoppingCart(purchase: TicketPurchaseDto): Promise<void> {
  const currentCount = getPurchaseItemCount(purchase.ticketTypeId, purchase.slotId);
  const ticketTypeWithSlotsMetadata = ticketShopService.getTicketTypeWithSlotMetadata(purchase.ticketTypeId, purchase.slotId);
  if (!ticketTypeWithSlotsMetadata) {
    return Promise.reject(new Error('SOMETHING_BIG_CHANGED'));
  }
  const { lowBoundTicketCount, ticketsLeft, highBoundTicketCount } = ticketTypeWithSlotsMetadata;
    
  if (
    purchase.count + currentCount < lowBoundTicketCount ||
    purchase.count + currentCount > Math.min(ticketsLeft, highBoundTicketCount)
  ) {
    return Promise.reject(new Error('SOMETHING_BIG_CHANGED'));
  }
  return setItemInShoppingCartWithQuantity({ ...purchase, count: purchase.count + currentCount });
}
  
  /**
   * Sets the quantity of the given item in the shopping cart
   * @param item The item to have its quantity set in the shopping cart
   * @returns {Promise<void>} A promise that resolves when the item has been set in the shopping cart
   */
  export function setItemInShoppingCartWithQuantity(item: TicketPurchaseDto): Promise<void> {
    const maxCount = ticketShopService.getMaxCountForTicketType(item.ticketTypeId, item.slotId);
    const currentCount = getPurchaseItemCount(item.ticketTypeId, item.slotId);
    if (!maxCount || maxCount < currentCount) {
      return Promise.reject(new Error('Not enough tickets left'));
    }
    store.dispatch(createBackup());
    store.dispatch(setItemWithQuantity(item));
    return updateCart();
  }
  
  /**
   * Deletes all items of a kind from the shopping cart
   * @param item The item to be deleted entirely from the shopping cart
   */
  export function deleteAllItemsOfAKindFromShoppingCart(item: TicketPurchaseDto): Promise<void> {
    store.dispatch(createBackup());
    store.dispatch(deleteAllItemsOfAKind(item));
    return updateCart();
  }


  /**
   * Adds a discounf to the shopping cart
   * @param discount A discount object with a code and a number of times it has been used
   * @returns {Promise<void>} A promise that resolves when the discount has been added to the shopping cart
   */
  export function addDiscountToShoppingCart(discount: { code: string, times: number }) : Promise<void> {
    store.dispatch(createBackup());
    store.dispatch(addDiscount(discount));
    return updateCart();
  }

  /**
   * Removes a discount from the shopping cart
   * 
   * @param discountCode The code of the discount to be removed
   * @returns {Promise<void>} A promise that resolves when the discount has been removed from the shopping cart
   */
  export function removeDiscountFromShoppingCart(discountCode: string): Promise<void> {
    store.dispatch(createBackup());
    store.dispatch(removeDiscount(discountCode));
    return updateCart();
  }

  /**
   * Gets the number of tickets of the given type and slot purchased as in the shopping cart
   * @param ticketTypeId The id of the given ticket type
   * @param slotId The id of the slot
   * @returns {number} The number of tickets of the given type and slot in the shopping cart
   */
  export function getPurchaseItemCount(ticketTypeId: number, slotId: number): number {
    return store.getState().shoppingCart.purchases
      .filter((cur): boolean => cur.ticketTypeId === ticketTypeId && cur.slotId === slotId)
      .reduce((acc, cur): number => acc + cur.count, 0);
  }

/**
 * Gets the number of purchases that are currently in the cart for the given id
 * @param slotId Id of the slot
 * @returns {number} the amount of purchases for the given slot id
 */
export function getAmountForSlotId(slotId: number): number {
  return store
    .getState()
    .shoppingCart.purchases.filter(
      (p): boolean => p.slotId === slotId && !getTicketTypeById(p.ticketTypeId)?.alwaysAvailable,
    )
    .map((p): number => p.count)
    .reduce((acc, i): number => acc + i, 0);
}

/**
 * Clears the shopping cart
 */
export function clearCartState(): void {
  store.dispatch(clearCart());
}

/** 
 * Get rotterdampasses from cart for specific slot
 */
export function getRotterdampassesFromCart() : string[] {
  return store.getState().shoppingCart.purchases.flatMap((d): string[] => d.rotterdampasses);
}