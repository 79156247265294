import { clearUserDetails, setUserDetails } from '../redux/slices/userDetailsSlice';
import { store } from '../redux/store';
import { UserDetailsInputType, UserDetailsType } from '../types/userDetails';

export function saveUserDetails(details: UserDetailsType): void {
  store.dispatch(setUserDetails(details));
}
export function clearUserDetailsFromStore(): void {
  store.dispatch(clearUserDetails());
}

export function getUserDetailsTypeFromUserDetailsInputType({
  salutation,
  firstName,
  lastName,
  birthdate,
  email,
  zipCode,
  city,
}: UserDetailsInputType): Promise<UserDetailsType> {
  if (!salutation || !firstName || !lastName || birthdate === null || !email || !zipCode || !city)
    throw new Error('USER_DETAILS_MISSING');
  return Promise.resolve({
    salutation,
    firstName,
    lastName,
    birthdate,
    email,
    zipCode,
    city,
  });
}
