import { combineReducers } from 'redux';
import applicationReducer from './slices/applicationSlice';
import shoppingCartReducer from './slices/shoppingCartSlice';
import userDetailsReducer from './slices/userDetailsSlice';
import shopReducer from './slices/shopSlice';

const rootReducer = combineReducers({
  application: applicationReducer,
  shoppingCart: shoppingCartReducer,
  userDetails: userDetailsReducer,
  shop: shopReducer,
});

export default rootReducer;
