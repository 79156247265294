import React, { ComponentProps, useEffect, useRef, useState } from 'react';
import { FaChevronUp, FaTrash } from 'react-icons/fa6';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { TbMinus, TbPlus } from 'react-icons/tb';
import { toast } from 'react-toastify';
import IconButton from '../buttons/IconButton';
import Button from '../buttons/Button';
import useShop from '../../hooks/selectors/useShop';
import useDateFormat from '../../hooks/useDateFormat';
import useShoppingCart from '../../hooks/selectors/useShoppingCart';
import { ShoppingCartItem, TicketTypeWithSlotMetadataDto } from '../../types/cart';
import { cartService, ticketShopService } from '../../services';
import MinimalisticNumberInput from '../inputs/MinimalisticNumberInput';
import useTicketTypes from '../../hooks/selectors/useTicketTypes';
import useOutsideClickEffect from '../../hooks/useOutsideClickEffect';
import useCrashHandler from '../../hooks/useCrashHandler';
import DiscountInput from '../inputs/DiscountInput';
import DiscountList from './DiscountList';
import ExtendedLink from '../../routes/ExtendedLink';
import ContentPanel from '../layouts/ContentPanel';
import { DATE_PICKING_ROUTE } from '../../constants';
import useMediaQuery from '../../hooks/useMediaQuery';

interface ShoppingCartProps {
  next?: string;
  allowNext?: boolean;
  buttonProps?: ComponentProps<typeof Button>;
}

export default function ShoppingCart(props: ShoppingCartProps): JSX.Element {
  const isDesktop = useMediaQuery()(1024);

  return isDesktop ? <DesktopShoppingCart {...props} /> : <MobileShoppingCart {...props} />;
}

export function DesktopShoppingCart(props: ShoppingCartProps): JSX.Element {
  return (
    <ContentPanel className="w-1/2 min-w-[400px]">
      <ItemsPanel />
      <OverviewPanel {...props} />
    </ContentPanel>
  );
}

export function MobileShoppingCart(props: ShoppingCartProps): JSX.Element {
  const { items } = useShoppingCart();

  const [open, setOpen] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);
  useOutsideClickEffect((): void => setOpen(false), containerRef);
  useEffect((): void => {
    if (!items.length) setOpen(false);
  }, [items.length]);

  return (
    <div ref={containerRef}>
      <div className="h-[80px]" />
      <div className="fixed z-10 bg-white bottom-0 left-0 flex justify-center items-center shadow-[0px_-2px_10px_0px_#00000040] w-full">
        <OverviewPanel open={open} setOpen={setOpen} {...props} />
      </div>{' '}
      <div
        className={`fixed bottom-0 left-0 px-4 pt-5 pb-[120px] z-[9] w-full bg-white rounded-t-sb-lg transition-transform duration-300 ease-in-out transform flex justify-center ${
          open ? 'translate-y-0' : 'translate-y-full'
        }`}>
        <ItemsPanel />
      </div>
    </div>
  );
}

type OverviewPanelProps = {
  open?: boolean;
  setOpen?: (open: boolean) => void;
} & ShoppingCartProps;

function OverviewPanel({
  open,
  setOpen,
  next,
  buttonProps,
  allowNext,
}: OverviewPanelProps): JSX.Element {
  const { dateOfOrder } = useShop();
  const {
    price: { totalPrice },
    items,
  } = useShoppingCart();
  const localizedFormat = useDateFormat();
  const location = useLocation();

  return (
    <>
      <div className="flex items-center px-5 py-4 max-w-2xl justify-between w-full gap-2">
        <div className="flex gap-2 items-center transition-all duration-300">
          {setOpen && (
            <IconButton
              icon={FaChevronUp}
              onClick={(): void => setOpen(!open)}
              className={classNames('bg-sb-purple', {
                'rotate-180': open,
                'opacity-0 w-0': !items.length,
              })}
            />
          )}
          {(location.pathname !== DATE_PICKING_ROUTE || !!items.length) && (
            <div className="flex flex-col">
              <p className="text-[15px] leading-[21px]">
                {localizedFormat(dateOfOrder, 'EEEE dd MMMM')}
              </p>
              <p className="font-ginto-bold text-[24px] leading-[24px]">
                €{(totalPrice.discountedPrice ?? totalPrice.price).toFixed(2) || '--,--'}
              </p>
            </div>
          )}
        </div>
        {!allowNext ? (
          <Button
            variant="pink"
            className={buttonProps ? buttonProps.className : 'invisible'}
            {...buttonProps}
            onClick={(event): void => {
              if (setOpen) {
                setOpen(false);
              }
              if (buttonProps?.onClick) {
                buttonProps?.onClick(event);
              }
            }}
          />
        ) : (
          next && (
            <ExtendedLink to={next}>
              <Button variant="pink" {...buttonProps}
                      onClick={(event): void => {
                        if (setOpen) {
                          setOpen(false);
                        }
                        if (buttonProps?.onClick) {
                          buttonProps?.onClick(event);
                        }
                      }}/>
            </ExtendedLink>
          )
        )}
      </div>
    </>
  );
}

function ItemsPanel(): JSX.Element {
  const { dateOfOrder, ticketTypes } = useShop();
  const {
    price: { bookingFee },
    items,
  } = useShoppingCart();
  const { t } = useTranslation('translation', { keyPrefix: 'component.shoppingCart' });
  const localizedFormat = useDateFormat();

  return (
    <>
      <div className="flex flex-col w-full max-w-2xl px-5 max-h-[calc(100vh-200px)] no-scrollbar overflow-scroll">
        <h3 className="font-ginto-bold text-[24px] leading-[24px]">
          {localizedFormat(dateOfOrder, 'EEEE')} <br /> {localizedFormat(dateOfOrder, 'dd MMMM')}
        </h3>

        <div className="flex flex-col gap-4 mt-4 divide-y divide-black divide-opacity-30">
          {!!items.length && (
            <div className="grid grid-cols-3 w-full">
              <p className="font-ginto-bold text-[18px] leading-[24px]">{t('bookingFee')} </p>
              <div />
              <p className="flex justify-end">
                €{(bookingFee.discountedPrice ?? bookingFee.price).toFixed(2)}
              </p>
            </div>
          )}
          {Object.entries(_.groupBy(items, (i): number => i.categoryId)).map(([cId, cartItems]): null | JSX.Element => {
            if (!ticketTypes[+cId].length) return null;
            return (
              <div className="flex flex-col gap-2 pt-4" key={cId}>
                <p>{ticketTypes[+cId][0].categoryName}</p>
                {cartItems.map((cartItem, i): JSX.Element => (
                  <ShoppingCartItemComponent key={i} item={cartItem} />
                ))}
              </div>
            );
          })}
          <div className="flex flex-col gap-4 w-full">
            <DiscountInput className="pt-4" />
            <DiscountList />
          </div>
        </div>
      </div>
    </>
  );
}

function ShoppingCartItemComponent({ item }: { item: ShoppingCartItem }): JSX.Element | null {
  const {
    ticketTypeName,
    totalPrice: { price, discountedPrice },
    ticketPurchaseDto,
    slotTime,
    categoryId,
  } = item;
  const { alwaysAvailable } =
    useTicketTypes()[categoryId]?.find((t): boolean => t.id === ticketPurchaseDto.ticketTypeId) || {};
  const crashHandler = useCrashHandler();
  const format = useDateFormat();
  const { t } = useTranslation('translation', { keyPrefix: 'component.shoppingCart' });

  const [countInput, setCountInput] = useState<number>(item.ticketPurchaseDto.count);

  useEffect((): void => setCountInput(ticketPurchaseDto.count), [ticketPurchaseDto.count]);

  const handleQuantityChange = (count: number): void => {
    if (count === 0)
      cartService.deleteAllItemsOfAKindFromShoppingCart(item.ticketPurchaseDto).catch(crashHandler);
    else if (count > 0)
      cartService
        .setItemInShoppingCartWithQuantity({ ...ticketPurchaseDto, count })
        .catch((err): void => {
          crashHandler(err);
          if (err.message !== 'PRICES_CHANGED') {
            setCountInput(item.ticketPurchaseDto.count);
          }
        });
    else setCountInput(item.ticketPurchaseDto.count);
  };

  let slotMetaData: TicketTypeWithSlotMetadataDto | undefined;
  try {
    slotMetaData = ticketShopService.getTicketTypeWithSlotMetadata(
      item.ticketPurchaseDto.ticketTypeId,
      item.ticketPurchaseDto.slotId,
    );
  } catch (error: any) {
    toast.error(error.message);
  }

  const inCartOther =
    cartService.getAmountForSlotId(item.ticketPurchaseDto.slotId) - ticketPurchaseDto.count;
  const min = slotMetaData?.lowBoundTicketCount || 0;
  const max = Math.min(
    slotMetaData?.highBoundTicketCount || Number.MAX_SAFE_INTEGER,
    (slotMetaData?.ticketsLeft || Number.MAX_SAFE_INTEGER) - inCartOther,
  );

  return (
    <div className="grid grid-cols-3 w-full gap-1">
      <p className="font-ginto-bold text-[18px] leading-[24px] w-full">{ticketTypeName}</p>
      <div className="flex gap-1.5 w-full justify-center">
        { ticketPurchaseDto.rotterdampasses.length === 0 && <IconButton
          onClick={(): void => handleQuantityChange(ticketPurchaseDto.count - 1)}
          icon={TbMinus}
          className="w-fit"
          disabled={countInput <= min && min !== 1}
        />}
        <MinimalisticNumberInput
          value={countInput}
          onChange={(e): void => setCountInput(+e.target.value)}
          onBlur={(e): void => handleQuantityChange(+e.target.value)}
          min={min === 1 ? 0 : min}
          max={max}
          disabled={ticketPurchaseDto.rotterdampasses.length > 0}
          className="w-10 invalid:border-red-500 invalid:bg-red-100 bg-sb-light-blue"
        />
        { ticketPurchaseDto.rotterdampasses.length === 0 && <IconButton
          onClick={(): void => handleQuantityChange(ticketPurchaseDto.count + 1)}
          icon={TbPlus}
          className="w-fit"
          disabled={countInput >= max}
        />}
        <IconButton icon={FaTrash} onClick={(): void => handleQuantityChange(0)} className='border-none ml-2' iconClassName='text-gray-600 size-5'/>
      </div>
      <div className="flex gap-1 justify-end w-full">
        <p className={classNames({ 'line-through': discountedPrice !== undefined })}>
          €{price.toFixed(2)}
        </p>
        {discountedPrice !== undefined && <p>€{discountedPrice.toFixed(2)}</p>}
      </div>
      {!alwaysAvailable && (
        <>
          <p className="text-[15px] leading-[21px] opacity-50 w-full">{t('slot')}</p>
          <p className="text-[15px] leading-[21px] opacity-50 w-full justify-center flex">
            {format(slotTime.startTime, 'HH:mm')} / {format(slotTime.endTime, 'HH:mm')}
          </p>
        </>
      )}
    </div>
  );
}
