import React, { useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FaCheck, FaHourglass, FaXmark } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import {
  DATE_PICKING_ROUTE,
  ORDER_ROUTE,
  PAYMENT_ROUTE,
  SHOP_ROUTES,
  USER_DETAILS_ROUTE,
} from '../../constants';
import useShop from '../../hooks/selectors/useShop';
import { setPageIndex } from '../../redux/slices/shopSlice';
import ExtendedLink from '../../routes/ExtendedLink';
import { ProgressStatus } from '../../types/misc';

interface ProgressComponentProps {
  status?: ProgressStatus;
}

export default function ProgressComponent({ status }: ProgressComponentProps): JSX.Element {
  return status !== undefined ? (
    <SuccessFailureProgressComponent status={status} />
  ) : (
    <ShopProgressComponent />
  );
}

function ShopProgressComponent(): JSX.Element {
  const { pathname } = useLocation();
  const { pageIndex, ticketTypes } = useShop();
  const dispatch = useDispatch();
  const { t } = useTranslation('translation', { keyPrefix: 'component.shopProgress' });

  const containerRef = useRef<HTMLDivElement>(null);
  const stepRefs = useRef<HTMLDivElement[]>([]);
  const [progressWidth, setProgressWidth] = useState(0);

  const names = Object.entries(ticketTypes)
    .filter(([, tis]): boolean => !!tis.length && tis.some((ti): boolean => !!ti.slots.length))
    .toSorted(([a], [b]): number => +a - +b)
    .map(([, ti]): string | undefined => ti[0]?.categoryName)
    .filter(Boolean);
  const orderIndex = SHOP_ROUTES.indexOf(ORDER_ROUTE);
  const pathIndex = SHOP_ROUTES.indexOf(pathname);
  const steps = SHOP_ROUTES.flatMap((r): string | (string | undefined)[] =>
    r === ORDER_ROUTE ? names : t([ROUTE_NAMES[r as keyof typeof ROUTE_NAMES]]),
  );
  const progress =
    pathname === ORDER_ROUTE
      ? pageIndex + orderIndex
      : pathIndex + (orderIndex < pathIndex ? names.length - 1 : 0);

  const updateProgressWidth = (): void => {
    if (stepRefs.current.length && progress >= 0 && containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const progressStepRect = stepRefs.current[progress]?.getBoundingClientRect();

      setProgressWidth(progressStepRect.right - containerRect.left + 20);
    }
  };

  useLayoutEffect((): (() => void) => {
    if (progress < orderIndex) dispatch(setPageIndex(0));
    updateProgressWidth();
    window.addEventListener('resize', updateProgressWidth);

    return (): void => {
      window.removeEventListener('resize', updateProgressWidth);
    };
  }, [progress]);

  return (
    <div
      ref={containerRef}
      className="relative overflow-hidden w-full h-[60px] rounded-sb-lg flex bg-white items-center justify-between px-5 py-4">
      <div
        className="absolute left-0 top-0 h-[60px] transition-all duration-300 rounded-sb-lg bg-sb-light-blue"
        style={{ width: progressWidth }}
      />
      {steps.map((s, i): JSX.Element => (
        <ExtendedLink
          key={i}
          to={
            i >= orderIndex && i < orderIndex + names.length
              ? ORDER_ROUTE
              : SHOP_ROUTES[i - (orderIndex < i ? names.length - 1 : 0)]
          }
          onClick={(): void => {
            if (names.includes(s)) dispatch(setPageIndex(i - orderIndex));
          }}
          className={classNames('z-[1]', { 'disabled-link': i > progress })}>
          <div
            ref={(el): void => {
              if (el) stepRefs.current[i] = el;
            }}
            className="flex items-center gap-2 ">
            <div
              className={classNames(
                'flex-shrink-0 transition-none w-[30px] h-[30px] rounded-full flex items-center justify-center text-[15px] leading-[21px]',
                {
                  'bg-sb-purple': i <= progress,
                },
              )}>
              {i < progress ? <FaCheck className="w-3 h-3" /> : i + 1}
            </div>
            {progress === i && s?.toLowerCase()}
          </div>
        </ExtendedLink>
      ))}
    </div>
  );
}

const ROUTE_NAMES = {
  [DATE_PICKING_ROUTE]: 'date',
  [USER_DETAILS_ROUTE]: 'userDetails',
  [PAYMENT_ROUTE]: 'payment',
};

function SuccessFailureProgressComponent({
  status,
}: Required<ProgressComponentProps>): JSX.Element {
  const { t } = useTranslation('translation', { keyPrefix: 'general' });
  const { ticketTypes } = useShop();
  const names = Object.entries(ticketTypes)
    .filter(([, tis]): boolean => !!tis.length && tis.some((ti): boolean => !!ti.slots.length))
    .toSorted(([a], [b]): number => +a - +b)
    .map(([, ti]): string | undefined => ti[0]?.categoryName)
    .filter(Boolean);
  const steps = SHOP_ROUTES.flatMap((r): string | (string | undefined)[] =>
    r === ORDER_ROUTE ? names : [ROUTE_NAMES[r as keyof typeof ROUTE_NAMES]],
  );

  return (
    <div className="relative w-full h-[60px] rounded-sb-lg flex bg-white items-center justify-between px-5 py-4">
      <div
        className={`absolute w-full top-0 left-0 h-[60px] rounded-sb-lg ${
          status === ProgressStatus.SUCCESS ? 'bg-sb-light-green' 
          : status === ProgressStatus.PENDING ? 'bg-gray-100'
          : 'bg-sb-light-red'
        }`}
      />
      {steps.map((_, i): JSX.Element => (
        <div key={i} className="flex items-center gap-2 z-[1]">
          <div
            key={i}
            className={`flex-shrink-0 transition-none w-[30px] h-[30px] rounded-full flex items-center justify-center ${
              status === ProgressStatus.SUCCESS ? 'bg-sb-green' 
              : status === ProgressStatus.PENDING ? 'bg-gray-400'
              : 'bg-sb-red'
            }`}>
            {status ===  ProgressStatus.SUCCESS || i < steps.length - 1 ? (
              <FaCheck className="w-3 h-3" />
            ) : status === ProgressStatus.PENDING ? (
              <FaHourglass className="w-3 h-3" />
            ) : (
              <FaXmark className="w-3 h-3" />
            )}
          </div>
          {i === steps.length - 1 && (status === ProgressStatus.SUCCESS ? t('paid') : status === ProgressStatus.PENDING? t('pending') : t('failure'))}
        </div>
      ))}
    </div>
  );
}
