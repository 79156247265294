import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { API_URL } from '../constants';

const httpService: AxiosInstance = axios.create({
  baseURL: API_URL,
});

httpService.interceptors.request.use(
  (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const { url } = request;
    if (!url?.includes(':locationId') && !url?.includes(':ticketShopId')) return request;
    const params = new URLSearchParams(window.location.search);

    const ticketShopId = params.get('tId') ?? params.get('shopId');
    const locationId = params.get('lId');
    if (!ticketShopId || !locationId) throw new Error('MISSING_QUERY_PARAMS');

    request.url = request.url?.replaceAll(':locationId', locationId);
    request.url = request.url?.replaceAll(':ticketShopId', ticketShopId);

    return request;
  },
);

export default httpService;
