import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import useQuery from '../../hooks/useQuery';
import { DefaultURLQuery, EventType } from '../../types/misc';
import ShoppingCart from '../misc/ShoppingCart';
import { validateUserDetails } from '../../utils/validationUtils';
import Event from '../../utils/event';
import {
  DATE_PICKING_ROUTE,
  NOT_FOUND_ROUTE,
  ORDER_ROUTE,
  PAYMENT_ROUTE,
  SHOP_ROUTES,
  USER_DETAILS_ROUTE,
} from '../../constants';
import { setPageIndex } from '../../redux/slices/shopSlice';
import { RootState } from '../../types/redux';
import ProgressComponent from '../misc/ProgressComponent';
import { ticketShopService } from '../../services';
import useCrashHandler from '../../hooks/useCrashHandler';

export default function ShopLayout(): JSX.Element {
  const query = useQuery<DefaultURLQuery>();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation('translation', { keyPrefix: 'component.shopLayout' });
  const navigate = useNavigate();
  const crashHandler = useCrashHandler();
  const {
    shop: { pageIndex, ticketTypes, dateOfOrder },
    shoppingCart: { items },
    userDetails: { userDetails },
  } = useSelector((state: RootState): RootState => state);

  const shoppingCartFilled = !!items.length;
  const userDetailsInvalid = !Object.values(validateUserDetails(userDetails ?? {})).some(Boolean);
  const categories = Object.entries(ticketTypes).filter(
    ([, tis]): boolean => !!tis.length && tis.some((ti): boolean => !!ti.slots.length),
  );
  const lastCategoryReached = pageIndex === categories.length - 1;
  const params = Object.entries(query)
    .map(([key, value]): string => `${key}=${value}`)
    .join('&');

  /**
   * buttonProps - props for the button in the shopping cart
   * allowNext - whether the user can proceed to the next page
   * allowedPage - whether user is allowed to be on current page
   */
  const [buttonProps, allowNext, allowedPage, title] = ((): [any, boolean | undefined, boolean | undefined, any] => {
    switch (location.pathname) {
      case DATE_PICKING_ROUTE:
        return [
          { children: t('continue'), disabled: !categories.length },
          !!categories.length,
          true,
          t('titles.date'),
        ];
      case ORDER_ROUTE:
        return [
          {
            children: t('continue'),
            onClick: (): void => {
              if (!lastCategoryReached) dispatch(setPageIndex(pageIndex + 1));
              if (lastCategoryReached && !shoppingCartFilled) toast.error(t('cartEmpty'));
            },
          },
          lastCategoryReached && shoppingCartFilled,
          !!categories.length && categories.some(([, tis]): boolean => tis.some((ti): boolean => !!ti.slots.length)),
          t('titles.order'),
        ];
      case USER_DETAILS_ROUTE:
        return [
          {
            children: t('toPayment'),
            onClick: (): void => { Event.emit(EventType.SUBMIT_USER_DETAILS); },
          },
          userDetailsInvalid,
          shoppingCartFilled,
          t('titles.userDetails'),
        ];
      case PAYMENT_ROUTE:
        return [undefined, false, userDetailsInvalid, t('titles.payment')];
      default:
        return [undefined, undefined, undefined, ''];
    }
  })();

  useEffect((): void => {
    if (SHOP_ROUTES.includes(location.pathname) && !allowedPage)
      navigate(
        `${links[location.pathname as keyof typeof links]?.prev ?? NOT_FOUND_ROUTE}?${params}`,
        { replace: true },
      );
  }, [allowedPage, location.pathname]);

  useEffect((): void => {
    ticketShopService.fetchTicketTypes().catch(crashHandler);
  }, [dateOfOrder]);

  return query.tId && query.lId && [...SHOP_ROUTES, '/'].includes(location.pathname) ? (
    <>
      <div className="flex w-full lg:w-1/3 lg:pt-5 mx-auto ">
        <ProgressComponent />
      </div>
      <h1 className="text-[24px] leading-[24px] font-ginto-bold text-center hidden lg:block text-white">
        {title}
      </h1>
      <div className="flex flex-col gap-4 lg:flex-row">
        <Outlet />
        {location.pathname !== DATE_PICKING_ROUTE && (
          <ShoppingCart
            next={links[location.pathname as keyof typeof links]?.next}
            allowNext={allowNext}
            buttonProps={buttonProps}
          />
        )}
      </div>
    </>
  ) : (
    <Navigate to={NOT_FOUND_ROUTE} />
  );
}

const links = {
  [DATE_PICKING_ROUTE]: { next: ORDER_ROUTE, prev: undefined },
  [ORDER_ROUTE]: { next: USER_DETAILS_ROUTE, prev: DATE_PICKING_ROUTE },
  [USER_DETAILS_ROUTE]: { next: PAYMENT_ROUTE, prev: ORDER_ROUTE },
  [PAYMENT_ROUTE]: { prev: USER_DETAILS_ROUTE, next: undefined },
};
