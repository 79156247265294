import { createTransform } from 'redux-persist';
import { ShopState, UserDetailsState } from '../types/redux';

export const shopTransform = createTransform(
  (incoming: ShopState): ShopState => incoming,
  (outgoing: ShopState): ShopState => {
    return {
      ...outgoing,
      dateOfOrder: new Date(outgoing.dateOfOrder),
    };
  },
  { whitelist: ['shop'] },
);

export const userDetailsTransform = createTransform(
  (incoming: UserDetailsState): UserDetailsState => incoming,
  (outgoing: UserDetailsState): UserDetailsState => {
    const { userDetails } = outgoing;
    const { birthdate } = userDetails || {};
    if (!userDetails || !birthdate) return outgoing;
    return {
      ...outgoing,
      userDetails: {
        ...userDetails,
        birthdate: new Date(birthdate),
      },
    };
  },
  { whitelist: ['userDetails'] },
);
