import { useSelector } from 'react-redux';
import { RootState } from '../../types/redux';
import { TicketType } from '../../types/order';

export default function useTicketPage(): TicketType[] | undefined {
  return useSelector(
    ({ shop: { ticketTypes, pageIndex } }: RootState): TicketType[] | undefined => {
      return ticketTypes[
        Object.entries(ticketTypes)
          .filter(([, v]): boolean => !!v.length)
          .map(([k]): string => k)
          .map(Number)
          .sort((a, b): number => a - b)[pageIndex]
      ]
      .map((t): TicketType => ({ ...t, slots: t.slots.toSorted((a, b): number => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()) }));
    },
  );
}

